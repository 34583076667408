import React, { useEffect, useState } from "react";

import { UserContext } from "../../UserContext";
import axios from 'axios'

import { useParams } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { CSVLink, CSVDownload } from "react-csv";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    Row,
    Table,
    Button,
    Spinner
  } from "reactstrap";
import withdrawals from "pages/SinglePages/withdrawals";

import io from "socket.io-client"


function Reports() {

    const{auth,user} = React.useContext(UserContext)
    const[userid, setUserid] =  React.useState('');

    
    const[email, setEmail] =  React.useState('');

    const[depositData, setDepositData] =  React.useState([]);
    const[depositHeaders, setDepositHeaders] =  React.useState([]);

    
    const[withdrawalsData, setWithdrawalsData] =  React.useState([]);
    const[withdrawalsHeaders, setWithdrawalsHeaders] =  React.useState([]);

    
    const[productSalesData, setAllProductSales] =  React.useState([]);
    const[productSalesHeaders, setProductSalesHeaders] =  React.useState([]);

    
    const[userInfoData, setUserInfoData] =  React.useState([]);
    const[userInfoHeaders, setUserInfoHeaders] =  React.useState([]);

    const[transactions, setTransactionData] =  React.useState([]);
    const[transactionHeader, setTransactionHeader] =  React.useState([]);

    const[dateOptions, setDateOptions] =  React.useState([]);
    const[referralData, setReferralData] =  React.useState([]);
    const[referralHeader, setReferralHeader] =  React.useState([]);

    
    const[depositAllHeaders, setDepositAllHeaders] =  React.useState([]);
    
    const[displayDownloadButton, setDisplayDownloadButton] =  React.useState(true);

    const[depositSpinner, setDepositSpinner] =  React.useState(true);
    const[withdrawSpinner, setWithdrawSpinner] =  React.useState(true);
    const[transactionSpinner, setTransactionSpinner] =  React.useState(true);
    const[productSpinner, setProductSpinner] =  React.useState(true);
    const[userSpinner, setUserSpinner] =  React.useState(true);

    
    const[depositAllData, setDepositAllData] =  React.useState([]);
    const[depositAllSpinner, setDepositAllSpinner] =  React.useState(true);
    
    

    React.useEffect( () => {
  
        if (auth != null) {
          if (!auth) {
            window.location.href="/login"
          }else{
           if(user)
           {
            setEmail(user.user.email)
            getReferralDates()
                setUserid(user.user.userid);
                generateDepositData()
                generateWithdrawalData()
                getProductsData()
                getUsersData()
                getAllUserTotalDeposit()
                

                console.log('removed listener');
                socket.off('get transactions', getTransactionsNew)
           }
    
          }
        }
      }, [auth])

      const socket = io('https://nodeserver-conenamibia-8459bdb4b4fc.herokuapp.com', {});
window.socket = socket;


    //   const getDepositData = async(button) => {

    //     await generateDepositData()
        
        
    //   }

    const getAllUserTotalDeposit = async() => {

      // userid: user.userid,
      // name: name,
      // usdtAmount: totalUSDT,
      // zarAmount: totalZAR,
      // btcAmount: totalBTC,
      // txid: user.txid,
      // date: user.createdAt
      
      var headers = [
        { label: "Userid", key: "userid" },
        { label: "User", key: "name" },
        { label: "USDT", key: "usdtAmount" },
        { label: "ZAR", key: "zarAmount" },
        { label: "BTC", key: "btcAmount" },
        { label: "TXID", key: "txid" },
        { label: "Date", key: "date" }
      ];


      setDepositAllHeaders(headers)

      var deposits = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/get-all-user-total-products`).then(async (res)=>{
        console.log("All user deposits ", res.data)
        if(res.data.status === 200)
        {
            // console.log(res.data.deposit)
            setDepositAllData(res.data.totals)

            setDepositAllSpinner(false)

            return true;
            
        }else{
            return false;
        }
    })
    }

      const generateDepositData = async() => {
        var headers = [
            { label: "Userid", key: "userid" },
            { label: "User", key: "names" },
            { label: "Amount", key: "amount" },
            { label: "Currency", key: "currency" },
            { label: "status", key: "status" },
            { label: "TXID", key: "txid" },
            { label: "Date", key: "updatedAt" }
          ];


          setDepositHeaders(headers)

          var deposits = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/find-all-deposits`).then(async (res)=>{
            if(res.data.status === 200)
            {
                // console.log(res.data.deposit)
                setDepositData(res.data.deposit)

                setDepositSpinner(false)

                return true;
                
            }else{
                return false;
            }
        })
      }

      const generateWithdrawalData = async() => {
        var headers = [
            { label: "Userid", key: "userid" },
            { label: "User", key: "names" },
            { label: "Transaction ID", key: "trans_id" },
            { label: "Amount", key: "amount" },
            { label: "Currency", key: "currency" },
            { label: "status", key: "status" },
            { label: "Withdrawal Address", key: "w_address" },
            { label: "Date", key: "updatedAt" }
          ];


          setWithdrawalsHeaders(headers)

          var deposits = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/find-all-withdrawals`).then(async (res)=>{
            if(res.data.status === 200)
            {
                // console.log(res.data.deposit)
                setWithdrawalsData(res.data.deposit)
                setWithdrawSpinner(false)

                return true;
                
            }else{
                return false;
            }
        })
      }

      const getProductsData = async() => {
        var headers = [
            { label: "Userid", key: "userid" },
            { label: "User", key: "names" },
            { label: "Transaction ID", key: "trans_id" },
            { label: "Amount", key: "amount" },
            { label: "Currency", key: "currency" },
            { label: "status", key: "status" },
            { label: "Withdrawal Address", key: "w_address" },
            { label: "Date", key: "updatedAt" }
          ];


        //   setProductSalesHeaders(headers)

          var getdata = await axios.post(process.env.REACT_APP_BASE + "api/get-all-product-sales", {}).then(async res2 =>{
            // console.log("sales is ", res2.data)
            if(res2.data.status == 200)
            {
                // console.log(" data ", res2.data)
              await generateProductData(res2.data.productInterests)     
              setProductSpinner(false);
            //   setDisplaySpinner(false);
            }
                    
        })
      }

      const generateProductData = async(products) => {
          // Table Data
const productData = [];
var x = 0;
        if(typeof products !== 'undefined' && products.length > 0) {
            products.forEach(productsale => {
                var numid = x.toString();
            //=================  Donut chart ===========================================================
                var transtatus = '---';
                if(productsale.status == '1')
                {
                 transtatus = 'Open'
                }else if(productsale.status == '2'){
                 transtatus = 'Closed'
                }else{
                 transtatus = 'Closed'
                }
            
                var month1 = '$0'
                var month2 = '$0'
                var month3 = '$0'
            
                var pers1 = '0'
                var pers2 = '0'
                var pers3 = '0'
                
              
                // productsale.interests[0].forEach(interestPlan => {
            
                // console.log(productsale.interests[0], productsale.interests[0])
                 
                if(typeof productsale.interests[0][productsale.planid + '_1'] !== 'undefined')
                {
                  month1 = '$' + productsale.interests[0][productsale.planid + '_1']['amount']
                  pers1 = productsale.interests[0][productsale.planid + '_1']['persentage'] + '%'
                }
                  
                
                if(typeof productsale.interests[0][productsale.planid + '_2'] !== 'undefined')
                {
                  month2 = '$' + productsale.interests[0][productsale.planid + '_2']['amount']
                  pers2 = productsale.interests[0][productsale.planid + '_2']['persentage'] + '%'
                }
            
                if(typeof productsale.interests[0][productsale.planid + '_3'] !== 'undefined')
                {
                  month3 = '$' + productsale.interests[0][productsale.planid + '_3']['amount']
                  pers3 = productsale.interests[0][productsale.planid + '_3']['persentage'] + '%'
                }
                // }
             
                 var productDataObj = {
                    userid: productsale.userid,
                    user: productsale.name,
                    planid: productsale.planid,
                    transaction_id: productsale.transaction_id,
                    amount: productsale.amount,
                    contributionDate: productsale.contributionDate,
                    start_date: productsale.start_date,
                    expire_date: productsale.expire_date,
                    currency: productsale.currency,
                    status: transtatus,
                     month1: month1,
                     month2: month2,
                     month3: month3,
                     pers1: pers1,
                     pers2: pers2,
                     pers3: pers3,
                 };
            
                 productData.push(productDataObj)
            
                 x = x+1;
             });
            }

            // console.log("product data ", productData)

            setAllProductSales(productData)
      }

      const getUsersData = async() => {
        
        var getdata = await axios.post(process.env.REACT_APP_BASE + "api/get-all-user-info", {}).then(async res2 =>{

            var headers = [
                { label: "Userid", key: "userid" },
                { label: "Name", key: "name" },
                { label: "Lastname", key: "lastname" },
                { label: "Cell", key: "cell" },
                { label: "Email", key: "email" },
                { label: "Status", key: "u_status" },
                { label: "Fica Status", key: "fica_status" },
                { label: "Agent", key: "referral" },
                { label: "USDT Wallet Balance", key: "usdt" },
                { label: "ZAR Wallet Balance", key: "zar" },
                { label: "BTC Wallet Balance", key: "btc" },
                { label: "USDT Product Total", key: "productUSDT" },
                { label: "Date", key: "createAt" }
              ];
    
    
              setUserInfoHeaders(headers)
            //   console.log(" userdata ", res2.data)
            if(res2.data.status == 200)
            {
                
                setUserInfoData(res2.data.data)
                setUserSpinner(false)
            //   setDisplaySpinner(false);
            }
                    
        })
      }

      const getTransactions = async(newemail) => {
        socket.on('get transactions', getTransactionsNew(newemail));
        console.log("email is ", newemail)
        socket.emit('get transactions', {email: newemail});
      }

      const getTransactionsNew = async(email) => {
        console.log("Call this")
        return email
      }

      
      const getTransactionsOld = async() => {
        var getdata = await axios.get(process.env.REACT_APP_BASE + "api/get-all-transactions", {}).then(async res2 =>{

            var headers = [
                { label: "Userid", key: "userid" },
                { label: "Name", key: "names" },
                { label: "Amount", key: "amount" },
                { label: "Cell", key: "cell" },
                { label: "Type", key: "tran_type" },
                { label: "Status", key: "status" },
                { label: "Currency", key: "currency" },
                { label: "Agent", key: "referral" },
                { label: "USDT Withdrawable Balance", key: "usdt" },
                { label: "ZAR Withdrawable Balance", key: "zar" },
                { label: "BTC Withdrawable Balance", key: "btc" },
                { label: "USDT Products Balance", key: "productUSDT" },
                { label: "Transactions ID", key: "trans_id" },
                { label: "Date", key: "createdAt" },
              ];
    
    
              setTransactionHeader(headers)
              console.log(" transactions ", res2.data)
            if(res2.data.status == 200)
            {
                setTransactionSpinner(false)
                setTransactionData(res2.data.transactions)
            //   setDisplaySpinner(false);
            }
                    
        })
      }

      const getReferralDates = async() => {
        var getdata = await axios.post(process.env.REACT_APP_BASE + "api/get-referral-dates", {}).then(async res2 =>{
            console.log(res2.data)
            if(res2.data.status == 200)
            {
                await onReferralDateChanged('all')
                setDateOptions(res2.data.dates)
              
               
                // dateOptions.push(res2.data.dates)
            }
        })
      }

      const onReferralDateChanged = async(date) => {
        setDisplayDownloadButton(false)

        var dateObj = {
            date: date
        }

        var getdata = await axios.post(process.env.REACT_APP_BASE + "api/get-referral-dates-values", dateObj).then(async res2 =>{

            var headers = [
                { label: "Userid", key: "userid" },
                { label: "From User", key: "from_userid" },
                { label: "Amount", key: "amount" },
                { label: "Percentage", key: "percentage" },
                { label: "Info", key: "userinfo" },
                { label: "Date", key: "createdAt" },

              ];

              setReferralHeader(headers)

              console.log('data is ', res2.data)
      
            if(res2.data.status == 200)
            {
                setReferralData(res2.data.referrals)
                
                setDisplayDownloadButton(true)
            }
        })
      }

      const getTransactionReport = async() => {
        toast.success('Generating Report, an Email will be sent!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          });
        getTransactions(email)
      }

     

  return (
    <div className='page-content'>
      <ToastContainer
  position="top-right"
  autoClose={5000}
  hideProgressBar={false}
  newestOnTop={false}
  closeOnClick
  rtl={false}
  pauseOnFocusLoss
  draggable
  pauseOnHover
  theme="dark"
  />
        <Container fluid>

        {/* Render Breadcrumbs */}
        <Breadcrumbs title="Reports" breadcrumbItem="Reports" />
            <Row>
                {/* <Col md="4" sm="6">
                    <a >
                        <Card className="text-center user-options-card shadow-2xl border rounded border-black">
                            <CardBody>
                                <h5 className="font-size-15 mb-1">
                                    Withdrawals
                                </h5>
                            </CardBody>
                        </Card>
                    </a>
                </Col> */}

                {/* <Col md="4" sm="6">
                    <a >
                        <Card className="text-center user-options-card shadow-2xl border rounded border-black">
                            <CardBody>
                                <h5 className="font-size-15 mb-1">
                                    Deposits
                                </h5>
                            </CardBody>
                        </Card>
                    </a>
                </Col> */}

                    {/* <Spinner type="grow" className="ms-2" color="primary" />
                    <Spinner type="grow" className="ms-2" color="secondary" />
                    <Spinner type="grow" className="ms-2" color="success" />
                    <Spinner type="grow" className="ms-2" color="danger" />
                    <Spinner type="grow" className="ms-2" color="warning" />
                    <Spinner type="grow" className="ms-2" color="info" />
                    <Spinner type="grow" className="ms-2" color="light" />
                    <Spinner type="grow" className="ms-2" color="dark" /> */}

                {depositSpinner ?
                 <Spinner type="grow" className="ms-2" color="primary" />
                 :
                <Col md="4" sm="6">
                    <CSVLink
                    filename={"all-deposit.csv"}
                    className="btn btn-primary rounded border-2-primary w-100"
                    data={depositData}
                    headers={depositHeaders}
                    >
                    Deposits
                    </CSVLink>
                </Col>}

                {depositAllSpinner ?
                 <Spinner type="grow" className="ms-2" color="primary" />
                 :
                <Col md="4" sm="6">
                    <CSVLink
                    filename={"all-deposit.csv"}
                    className="btn btn-primary rounded border-2-primary w-100"
                    data={depositAllData}
                    headers={depositAllHeaders}
                    >
                    All User Deposits
                    </CSVLink>
                </Col>}
 
                {withdrawSpinner ?
                 <Spinner type="grow" className="ms-2" color="secondary" />
                 :
                <Col md="4" sm="6">
                    <CSVLink
                     filename={"all-withdrawals.csv"}
                    className="btn btn-primary rounded border-2-primary w-100"
                    data={withdrawalsData}
                    headers={withdrawalsHeaders}
                    // onClick={e => {getDepositData(e.target.value)}}
                    >
                    Withdrawals
                    </CSVLink>
                </Col>}
                
    
                <Col md="4" sm="6">
                {productSpinner?
                                 <Spinner type="grow" className="ms-2" color="success" />
                                 :
                    <CSVLink
                     filename={"all-productsales.csv"}
                    className="btn btn-primary rounded border-2-primary w-100"
                    data={productSalesData}
                    // headers={productSalesHeaders}
                    // onClick={e => {getDepositData(e.target.value)}}
                    >
                    Product Sales
                    </CSVLink>}
                </Col>

                </Row>
                
                <Row className="mt-4">

                <Col md="4" sm="6">
                {userSpinner ?
                 <Spinner type="grow" className="ms-2" color="primary" />
                 :
                    <CSVLink
                     filename={"all-userinfo.csv"}
                    className="btn btn-primary rounded border-2-primary w-100"
                    data={userInfoData}
                    headers={userInfoHeaders}
                    // onClick={e => {getDepositData(e.target.value)}}
                    >
                    User Info
                    </CSVLink>
}
                </Col>

  
                <Col md="4" sm="6">

       
                    <Button onClick={(e) => {getTransactionReport(e.target.value)}} className="btn btn-primary rounded border-2-primary w-100">Transaction Report</Button>
                        
                </Col>

            </Row>

            <Row className="mt-4">
                <Col md="6" sm="12">
                    <label className="">Agent Proceeds Paid</label>
                    <select onChange={(e) => {onReferralDateChanged(e.target.value)}} className="form-control" id="referrals">
                        <option value="all">All</option>
                        {typeof dateOptions !== 'undefined' && dateOptions.length > 0 ?
                        dateOptions.map((date, key) => (
                            <option key={key} value={date}>{date}</option>
                        ))
                        : ''}
                    </select>

                    <br />
                    
                    {displayDownloadButton ?
                    <CSVLink
                    className="btn btn-primary rounded border-2-primary w-100"
                    data={referralData}
                    headers={referralHeader}
                    // onClick={e => {getDepositData(e.target.value)}}
                    >
                    Download CSV
                    </CSVLink>
                    : ''}
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default Reports