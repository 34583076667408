import React from "react"

import { Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"

import avatar1 from "../../assets/images/users/avatar-1.jpg"
import profileImg from "../../assets/images/profile-img.png"

const WelcomeComp = ({username, role, adminLink, connectedUser}) => {
  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="bg-primary bg-soft">
          <Row>
            <Col xs="7">
              <div className="text-primary p-3">
                <h5 className="text-primary">Welcome Back !</h5>
                <p>C One Financial Services Namibia Dashboard</p>
              </div>
            </Col>
            <Col xs="5" className="align-self-end">
              
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row>
            <Col sm="8">
              {/* <div className="avatar-md profile-user-wid mb-4">
                <img
                  src={avatar1}
                  alt=""
                  className="img-thumbnail rounded-circle"
                />
              </div> */}
              <h5 className="font-size-15 text-truncate mt-3">{username}</h5>
              <p className="text-muted mb-0 text-truncate">Role: {role}</p>
              <p className="text-muted mb-0 text-truncate">Ref Link: <a href={`https://portal.c-onenamibia.com/register/${role==='agent' ? adminLink + '?userid='+ connectedUser : adminLink}`}  target="_blank" rel="noopener noreferrer" >https://portal.c-onenamibia.com/register/{role==='agent' ? adminLink + '?userid='+ connectedUser : adminLink}</a></p>
              {role === 'super' ? <a className="btn btn-primary" href="/report-list">View All Company Reports</a> : ''}
            </Col>

            <Col sm="4">
              <div className="pt-4">
                <div className="mt-4">
                  {/* <Link
                    to=""
                    className="btn btn-primary  btn-sm"
                  >
                    View Profile <i className="mdi mdi-arrow-right ms-1"></i>
                  </Link> */}
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
export default WelcomeComp
