import React, { useState } from "react"

import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import { Link } from "react-router-dom"

import io from "socket.io-client"

import { CSVLink, CSVDownload } from "react-csv";

const SuperAdminInfo = () => {

  const socket = io('https://nodeserver-conenamibia-8459bdb4b4fc.herokuapp.com', {});
    window.socket = socket;

    const [totalUSDT, setTotalUSDT] = useState(0)
    const [totalZAR, setTotalZAR] = useState(0)
    const [totalBTC, setTotalBTC] = useState(0)

    const [totalUSDTD, setTotalUSDTD] = useState(0)
    const [totalZARD, setTotalZARD] = useState(0)
    const [totalBTCD, setTotalBTCD] = useState(0)

    const [totalUSDTW, setTotalUSDTW] = useState(0)
    const [totalZARW, setTotalZARW] = useState(0)
    const [totalBTCW, setTotalBTCW] = useState(0)

    
    const [totalUSDTCombined, setTotalUSDTCombined] = useState(0)

    
    const [isDownloadingReport, setIsDownloadingReport] = useState(false)
    const [downloadReady, setDownloadReady] = useState(false)
    
    const [userBalances, setUserBalances] = useState([])

    const [total15, setTotal15] = useState(0)
    const [total161, setTotal161] = useState(0)
    const [total162, setTotal162] = useState(0)
    const [total18, setTotal18] = useState(0)
    const [total22, setTotal22] = useState(0)
    const [total26, setTotal26] = useState(0)
    const [total29, setTotal29] = useState(0)
    const [total33, setTotal33] = useState(0)
    const [total36, setTotal36] = useState(0)
    const [total45, setTotal45] = useState(0)

    const [total15Proceed, setTotal15Proceed] = useState(0)
    const [total161Proceed, setTotal161Proceed] = useState(0)
    const [total162Proceed, setTotal162Proceed] = useState(0)
    const [total18Proceed, setTotal18Proceed] = useState(0)
    const [total22Proceed, setTotal22Proceed] = useState(0)
    const [total26Proceed, setTotal26Proceed] = useState(0)
    const [total29Proceed, setTotal29Proceed] = useState(0)
    const [total33Proceed, setTotal33Proceed] = useState(0)
    const [total36Proceed, setTotal36Proceed] = useState(0)
    const [total45Proceed, setTotal45Proceed] = useState(0)
    
    const [avgPercentage, setAvgPercentage] = useState(1)
    const [avgPercentage2, setAvgPercentage2] = useState(1)
    const [totalAvg, setTotalAvg] = useState(0)

    const handleSliderChange = async(event) => {
      setAvgPercentage(parseFloat(event.target.value));
      await getTotalAvg()
    };
  
    const incrementPercentage = async() => {
      if (avgPercentage < 10) {
        setAvgPercentage((prevPercentage) => prevPercentage + 0.1);
        await getTotalAvg()
      }
    };
  
    const decrementPercentage = async() => {
      if (avgPercentage > 1) {
        setAvgPercentage((prevPercentage) => prevPercentage - 0.1);
        await getTotalAvg()
      }
    };

    const handleSliderChange2 = async(event) => {
      setAvgPercentage2(parseFloat(event.target.value));
      await getTotalAvg()
    };
  
    const incrementPercentage2 = async() => {
      if (avgPercentage2 < 10) {
        setAvgPercentage2((prevPercentage) => prevPercentage + 0.1);
        await getTotalAvg()
      }
    };
  
    const decrementPercentage2 = async() => {
      if (avgPercentage2 > 1) {
        setAvgPercentage2((prevPercentage) => prevPercentage - 0.1);
        await getTotalAvg()
      }
    };

    const getTotalAvg = async() => {
      var total = 0;
      total += total15 * (avgPercentage / 100)
      total += total161 * (avgPercentage / 100)
      total += total162 * (avgPercentage / 100)
      total += total18 * (avgPercentage / 100)
      total += total22 * (avgPercentage / 100)
      total += total26 * (avgPercentage / 100)
      total += total29 * (avgPercentage / 100)
      total += total33 * (avgPercentage / 100)
      total += total36 * (avgPercentage / 100)
      total += total45 * (avgPercentage2 / 100)
      setTotalAvg(total)
    }

      React.useEffect(() =>{
  
        console.log('added listener');
        socket.on('get walletBalances', onWalletBanceUpdate);
        socket.emit('get walletBalances', {});
       
      
        return () => {
          console.log('removed listener');
          socket.off('get walletBalances', onWalletBanceUpdate)
        }
      }, [])


      React.useEffect(() =>{
  
        console.log('added product listener');
        socket.on('get productBalances', onProductBanceUpdate);
        socket.emit('get productBalances', {});
       
      
        return () => {
          console.log('removed listener');
          socket.off('get productBalances', onProductBanceUpdate)
        }
      }, [])

      React.useEffect(() =>{
  
        console.log('added product Proceed Balances');
        socket.on('get proceedBalances', onProceedBalancesUpdate);
        socket.emit('get proceedBalances', {});
       
      
        return () => {
          console.log('removed listener');
          socket.off('get proceedBalances', onProceedBalancesUpdate)
        }
      }, [])

      
      React.useEffect(() =>{
  
        console.log('added deposit listener');
        socket.on('get userDeposits', onDepositBanceUpdate);
        socket.emit('get userDeposits', {});
       
      
        return () => {
          console.log('removed listener');
          socket.off('get userDeposits', onDepositBanceUpdate)
        }
      }, [])

      
      React.useEffect(() =>{
  
        console.log('added product listener');
        socket.on('get userWithdrawalTotal', onWithdrawalBanceUpdate);
        socket.emit('get userWithdrawalTotal', {});
       
      
        return () => {
          console.log('removed listener');
          socket.off('get userWithdrawalTotal', onWithdrawalBanceUpdate)
        }
      }, [])

      React.useEffect(() =>{
  
        console.log('added combined listener');
        socket.on('get combinedTotals', onCombinedUpdate);
        socket.emit('get combinedTotals', {});
       
      
        return () => {
      
          socket.off('get combinedTotals', onCombinedUpdate)
        }
      }, [])

      var useBalanceHeaders = [
        { label: "Userid", key: "userid" },
        { label: "Name", key: "name" },
        { label: "Lastname", key: "lastname" },
        { label: "USDT Balance", key: "usdt_balance" },
        { label: "BTC Balance", key: "btc_balance" },
        { label: "ZAR Balance", key: "zar_balance" },
        { label: "USDT Product Balance", key: "usdt_product_balance" },
      ];


      const onWalletBanceUpdate = async(data) => {
        console.log('got balance back and it is ', data.balance);
        if(data.balance.status === 200)
        {
            setTotalUSDT(data.balance.usdt)
            setTotalZAR(data.balance.zar)
            setTotalBTC(data.balance.btc)
        }
      }

      const onDepositBanceUpdate = async(data) => {
        console.log('got balance for deposit back and it is ', data.balance);
        if(data.balance.status === 200)
        {
            setTotalUSDTD(data.balance.usdt)
            setTotalZARD(data.balance.zar)
            setTotalBTCD(data.balance.btc)
        }
      }

      const onWithdrawalBanceUpdate = async(data) => {
        console.log('got balance back and it is ', data.balance);
        if(data.balance.status === 200)
        {
            setTotalUSDTW(data.balance.usdt)
            setTotalZARW(data.balance.zar)
            setTotalBTCW(data.balance.btc)
        }
      }

      const onProductBanceUpdate = async(data) => {
        console.log('got product balance back and it is ', data.balance);
        if(data.balance.status === 200)
        {
            setTotal15(data.balance['15'])
            setTotal161(data.balance['161'])
            setTotal162(data.balance['162'])
            setTotal22(data.balance['22'])
            setTotal29(data.balance['29'])
            setTotal33(data.balance['33'])
            setTotal36(data.balance['36'])
            setTotal45(data.balance['45'])
        }
      }

      const onProceedBalancesUpdate = async(data) => {
        console.log('got proceeds balance back and it is ', data.balance);
        if(data.balance.status === 200)
        {
            setTotal15Proceed(data.balance['15'])
            setTotal161Proceed(data.balance['161'])
            setTotal162Proceed(data.balance['162'])
            setTotal22Proceed(data.balance['22'])
            setTotal29Proceed(data.balance['29'])
            setTotal33Proceed(data.balance['33'])
            setTotal36Proceed(data.balance['36'])
            setTotal45Proceed(data.balance['45'])
        }
      };

      const onCombinedUpdate = async(data) => {
        console.log("rsults for vombined is ", data.balance)
        if(data.balance.status === 200)
        {
          setTotalUSDTCombined(data.balance.usdt)
        }
      }

      const onUserCSVGenerater = async(data) => {
        console.log('csv generater ', data.balance.transactions);
        if(data.balance.status === 200)
        {
          setUserBalances(data.balance.transactions)
        }
        setDownloadReady(true)
      }

      const generateUserReport = async() => {
        setIsDownloadingReport(true)
        socket.on('get userBalances', onUserCSVGenerater);
        socket.emit('get userBalances', {});
       
      
        return () => {
          console.log('removed listener');
          socket.off('get userBalances', onUserCSVGenerater)
   
        }
      }


  return (
    <React.Fragment>
      {" "}
        <Row>
            <Col sm="4" md={4}>
                <Card className="card text-white bg-dark ">
                    <CardBody>
                        <CardTitle className="mb-4 card-title">Withdrawable Balance</CardTitle>
                        <Row>
                            <Col sm="12">
                                <p className="card-text">USDT {totalUSDT && totalUSDT > 0 ? totalUSDT.toFixed(2) : 0}</p>
                                <p className="card-text">ZAR {totalZAR && totalZAR > 0 ? totalZAR.toFixed(2) : 0}</p>
                                <p className="card-text">BTC {totalBTC && totalBTC > 0 ? totalBTC.toFixed(6) : 0}</p>
                            </Col>
                        </Row>

                    </CardBody>
                </Card>
            </Col>

            <Col sm="4" md={4}>
                <Card className="card text-white bg-dark ">
                    <CardBody>
                        <CardTitle className="mb-4 card-title">Product Balance</CardTitle>
                        <Row>
                            <Col sm="12">
                                <p className="card-text">TAV {total15 && total15 > 0 ? total15.toFixed(2) : 0}</p>
                                <p className="card-text">Savings Bundle USDT {total161 && total161 > 0 ? total161.toFixed(2) : 0}</p>
                                <p className="card-text">Savings Bundle ZAR {total162 && total162 > 0 ? total162.toFixed(2): 0}</p>
                            

                                <p className="card-text">CIV USDT {total22 && total22 > 0 ? total22.toFixed(2) : 0}</p>
                        
                                <p className="card-text">Tokenized Bundle USDT {total29 && total29 > 0 ? total29.toFixed(2) : 0}</p>
                                <p className="card-text">Monetary Stability Fund USDT {total33 && total33 > 0 ? total33.toFixed(2) : 0}</p>
                                <p className="card-text">Synthetic Assets USDT {total36 && total36 > 0 ? total36.toFixed(2) : 0}</p>
                                <p className="card-text">Wealth Creator USDT {total45 && total45 > 0 ? total45.toFixed(2) : 0}</p>
                            </Col>
                        </Row>

                    </CardBody>
                </Card>
            </Col>
            

            <Col sm="4" md={4}>
                <Card className="card text-white bg-dark ">
                    <CardBody>
                        <CardTitle className="mb-4 card-title">Proceed Avg</CardTitle>
                        <Row>
                            <Col sm="12">
                              <label htmlFor="percentageSlider">Percentage: {avgPercentage.toFixed(2)}%</label>
                              <br />
                              <div style={{ display: 'flex', alignItems: 'center'}}>
                   
                                <button onClick={decrementPercentage}>-</button>
                                <div style={{ margin: '0 10px' }}>
                               
                                  <input
                                    type="range"
                                    id="percentageSlider"
                                    min={1}
                                    max={10}
                                    step={0.1}
                                    value={avgPercentage}
                                    onChange={handleSliderChange}
                                  />
                                </div>
                                <button onClick={incrementPercentage}>+</button>
                              </div>
                              <div className="mt-3 ">
                                <p className="card-text">TAV {total15 && total15 > 0 ? total15.toFixed(2) * (avgPercentage / 100) : 0}</p>
                                <p className="card-text">Savings Bundle USDT {total161 && total161 > 0 ? (total161 * (avgPercentage / 100)).toFixed(2) : 0}</p>
                                <p className="card-text">Savings Bundle ZAR {total162 && total162 > 0 ? (total162 * (avgPercentage / 100)).toFixed(2): 0}</p>
                            

                                <p className="card-text">CIV USDT {total22 && total22 > 0 ? (total22 * (avgPercentage / 100)).toFixed(2) : 0}</p>
                        
                                <p className="card-text">Tokenized Bundle USDT {total29 && total29 > 0 ? (total29.toFixed(2) * (avgPercentage / 100)).toFixed(2) : 0}</p>
                                <p className="card-text">Monetary Stability Fund USDT {total33 && total33 > 0 ? (total33.toFixed(2) * (avgPercentage / 100)).toFixed(2) : 0}</p>
                                <p className="card-text">Synthetic Assets USDT {total36 && total36 > 0 ? (total36.toFixed(2) * (avgPercentage / 100)).toFixed(2) : 0}</p>
                              </div>
                            </Col>
                            <br />
                            <br />
                            <Col sm="12" className="mt-3">
                              <label htmlFor="percentageSlider">Percentage: {avgPercentage2.toFixed(2)}%</label>
                              <br />
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                   
                                <button onClick={decrementPercentage2}>-</button>
                                <div style={{ margin: '0 10px' }}>
                               
                                  <input
                                    type="range"
                                    id="percentageSlider"
                                    min={1}
                                    max={10}
                                    step={0.1}
                                    value={avgPercentage2}
                                    onChange={handleSliderChange2}
                                  />
                                </div>
                                <button onClick={incrementPercentage2}>+</button>
                              </div>
                              <div className="mt-3 ">
                                <p className="card-text">Wealth Creator USDT {total45 && total45 > 0 ? (total45 * (avgPercentage2 / 100)).toFixed(2) : 0}</p>
                              </div>
                            </Col>

                            
                            <Col sm="12" className="mt-3">
                              <div className="mt-3 ">
                                <p className="card-text">Combined Total USDT {totalAvg && totalAvg > 0 ? totalAvg.toFixed(2) : 0}</p>
                              </div>
                            </Col>
                        </Row>

                    </CardBody>
                </Card>
            </Col>

            <Col sm="4" md={4}>
            <Card className="card text-white bg-dark ">
                    <CardBody>
                        <CardTitle className="mb-4 card-title">Deposit Total</CardTitle>
                        <Row>
                            <Col sm="12">
                                <p className="card-text">USDT {parseFloat(totalUSDTD) > 0 ? totalUSDTD.toFixed(2) : 0}</p>
                                <p className="card-text">ZAR {parseFloat(totalZARD) > 0 ? totalZARD.toFixed(2) : 0}</p>
                                <p className="card-text">BTC {parseFloat(totalBTCD) > 0 ? totalBTCD.toFixed(9) : 0}</p>
                            </Col>
                        </Row>

                    </CardBody>
                </Card>
            </Col>

            <Col sm="4" md={4}>
            <Card className="card text-white bg-dark ">
                    <CardBody>
                        <CardTitle className="mb-4 card-title">Withdraw Total</CardTitle>
                        <Row>
                            <Col sm="12">
                                <p className="card-text">USDT {totalUSDTW ? totalUSDTW.toFixed(2) : 0}</p>
                                <p className="card-text">ZAR {totalZARW ? totalZARW.toFixed(2) : 0}</p>
                                <p className="card-text">BTC {totalBTCW ? totalBTCW : 0}</p>
                            </Col>
                        </Row>

                    </CardBody>
                </Card>
            </Col>

            <Col sm="4" md={4}>
            <Card className="card text-white bg-dark ">
                    <CardBody>
                        <CardTitle className="mb-4 card-title">Combined Total</CardTitle>
                        <Row>
                            <Col sm="12">
                                <p className="card-text">USDT {parseFloat(totalUSDTCombined) > 0 ? totalUSDTCombined.toFixed(2) : 0}</p>
                            </Col>
                        </Row>

                    </CardBody>
                </Card>
            </Col>

            <Col sm="4" md={4}>
            <Card className="card text-white bg-dark ">
                    <CardBody>
                        <CardTitle className="mb-4 card-title">Users Report</CardTitle>
                        <Row>
                            <Col sm="12">
                              {!isDownloadingReport ?
                                <a  className="btn btn-primary" onClick={() => {generateUserReport()}} >Generate Report</a>
                                
                                : downloadReady?
                                // <p>test</p>
                                  <CSVLink
                    className="btn btn-primary rounded border-2-primary w-100"
                    data={userBalances}
                    headers={useBalanceHeaders}
                    // onClick={() => {generateUserReport()}}
                    >
                    Download User Report
                    </CSVLink>
                    : <p className="card-text">File assembly in progress. Our digital minions are hard at work! </p>}
                            </Col>
                        </Row>

                    </CardBody>
                </Card>
            </Col>

        </Row>
    </React.Fragment>
  )
}

export default SuperAdminInfo
